<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="保养品牌"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <template #header="params">
        <div class="search_box">
          <el-row :gutter="10">
            <el-col :span="4">
              <div class="search_item">
                <div>品牌名称：</div>
                <el-input v-model="searchData.brandName"></el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="search_item">
                <div>品牌类型：</div>
                <el-select v-model="searchData.brandType" clearable placeholder="请选择">
                  <el-option label="车型品牌" :value="1"></el-option>
                  <el-option label="零配件品牌" :value="2"></el-option>
                  <el-option label="油站品牌" :value="3"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 10px;">
            <el-col :span="4" :offset="20" style="text-align: end;">
              <el-button @click="pageHandler(params, 1, 'reset')">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>
      <template #navBar>
        <div class="table_nav_bar">
          <el-button type="primary" @click="brandAdd">新增品牌</el-button>
        </div>
      </template>
      <el-table-column label="品牌编号" fixed="left" prop="brandCode" :min-width="140"></el-table-column>
      <el-table-column label="品牌名称" prop="brandName" :min-width="160"></el-table-column>
      <el-table-column label="品牌Logo" prop="brandLogo" :min-width="160">
        <template slot-scope="scope">
          <img v-if="scope.row.brandLogo" class="insurance_logo_link" :src="scope.row.brandLogo" alt="加载失败">
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column label="品牌类型" prop="brandType" :min-width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.brandType==1">车型品牌</div>
          <div v-if="scope.row.brandType==2">零配件品牌</div>
          <div v-if="scope.row.brandType==3">油站品牌</div>
        </template>
      </el-table-column>
      <el-table-column label="操作人" prop="updateUser" :min-width="100"></el-table-column>
      <el-table-column label="操作时间" prop="updateTime" :min-width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" prop="" width="120">
        <template slot-scope="scope">
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(1, scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </my-table>

    <!-- 新增品牌弹窗 -->
    <el-dialog title="品牌操作" class="insurance_dialog" :visible.sync="brandAddVisible" width="80%">
      <el-form :model="brandForm" ref="brandForm" :rules="brandAddRules" label-width="120px">
        <div class="insurance_dialog_module">
          <el-form-item label="品牌名称：" prop="brandName">
            <el-input v-model="brandForm.brandName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="品牌Logo：" prop="brandLogo">
            <file-upload
              v-if="brandAddVisible"
              v-model="brandForm.brandLogo"
              :uploadBusinessInfo="{
                businessType: '0'
              }"
              :defaultFileList="brandLogoList"
              :maxNum="1"
              width="72px"
              height="72px"
              @uploadSuccess="uploadSuccess('purchaseImage')"
            ></file-upload>
          </el-form-item>
          <el-form-item label="品牌类型：" prop="brandType">
            <el-radio-group v-model="brandForm.brandType">
              <el-radio :label="1">车型品牌</el-radio>
              <el-radio :label="2">零配件品牌</el-radio>
              <el-radio :label="3">油站品牌</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="brandAddVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import FileUpload from "@/components/scope/file-upload.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { FileUpload },
    data() {
    //这里存放数据
      return {
        listData: [{},{},{}],
        // 搜索数据
        searchData: {
          brandName: undefined,                  //品牌名称
          brandType: undefined,                  //品牌类型
        },
        listLoading: false,             //列表loading

        brandAddVisible: false,     //新增品牌弹窗状态

        brandForm: {             //新增品牌表单数据
          brandCode: '',                  //品牌编码
          brandName: '',                  //品牌名称
          brandLogo: "",                  //品牌Logo
          brandType: '',                  //品牌类型 1-车型品牌 2-零配件品牌 3-油站品牌
          tenantCode: '',
          orgCode: '',
        },
        brandAddRules: {            //表单验证
          brandName: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }],                  //品牌名称
          brandLogo: [{ required: true, message: '请上传品牌logo', trigger: 'change' }],                  //品牌logo
          brandType: [{ required: true, message: '请选择品牌类型', trigger: 'change' }],                  //品牌类型
        },
        brandLogoList: [],               //品牌logoList
      };
    },
  filters: {
    stateFilter: function(val) {
      
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num, type) {
      if(type == 'reset'){
        this.resetSearchData();
      }
      this.getBrandList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 新增品牌
    brandAdd(){
      this.brandForm = {                //新增品牌表单数据
        brandCode: '',                  //品牌编码
        brandName: '',                  //品牌名称
        brandLogo: "",                  //品牌Logo
        brandType: '',                  //品牌类型
      }
      this.brandAddVisible = true;
    },
    // 弹窗确认
    dialogConfirm(){
      this.$refs.brandForm.validate((valid) => {
        if (valid) {
          this.$store.dispatch('management/postMaintainBrandSave',{
            ...this.brandForm
          }).then(res => {
            this.$message.success('操作成功');
            this.$refs.list.init();
            this.brandAddVisible = false;
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 图片上传成功
    uploadSuccess(e){
      // this.$refs.form.clearValidate(e)
    },

    // 表格内操作
    tableHandle(type, row) {
      switch (type) {
        case 1:     //编辑
          this.brandAddVisible = true;
          Object.keys(this.brandForm).forEach(key => {
            this.brandForm[key] = row[key]
          })
          this.brandLogoList = JSON.parse(JSON.stringify([{url: row.brandLogo}]))
          break;

        default:
          break;
      }
    },

    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        brandName: undefined,
        brandType: undefined,
      }
    },

    // 获取品牌列表
    getBrandList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('management/postMaintainBrandList',{
        ...this.searchData,
        pageNum,
        pageSize,
      }).then(res => {
        if(res.code == 0){
          console.log(res)
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .search_box {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
    .table_nav_bar{
      width: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
      .el-button{
        margin-right: 10px;
      }
    }
    .table_row_rule{
      word-break: break-all; /** 换行，长单词会分成两行显示 **/
      text-overflow: ellipsis;/** 隐藏文本省略号 ... **/
      display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
      -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
      -webkit-line-clamp: 1; /** 显示的行数 **/
      overflow: hidden;  /** 隐藏超出的内容 **/;
    }
  }
  .handle_btn{
    padding: 0;
  }
  .channel_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >.el-input:nth-child(1){
      width: 260px;
    }
  }
  .channel_item:last-child{
    margin: 0;
  }
  .popularize_list_qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qrcode_img_src{
    position: absolute;
    top: -10000%;
    z-index: -10000;
  }
  .search_item{
    display: flex;
    align-items: center;
    >div:first-child{
      flex-shrink: 0;
    }
  }
  .insurance_logo_link{
    width: 72px;
    height: 72px;
    display: block;
  }
  .insurance_dialog{
    ::v-deep .el-dialog__body{
      padding: 10px 20px !important;
    }
    .el-input{
      width: 50%;
    }
    .insurance_dialog_module{
      .dialog_module_title{
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .title_line{
          width: 4px;
          height: 16px;
          background: #409EFF;
          margin-right: 5px;
          border-radius: 1px;
        }
      }
      .popup_table{
        .el-input{
          width: 100%;
        }
      }
    }
  }
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }
</style>
